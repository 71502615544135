import type { NextPage } from 'next';
import Head from 'next/head';

import AuthLayout from '../components/layout/auth-layout';
import { getLocale } from '../utils/get-locale';

const Home: NextPage = () => {
  return (
    <AuthLayout>
      <Head>
        <title>EO Swim - Homepage</title>
      </Head>
    </AuthLayout>
  );
};

export default Home;
