const ADMINS_AND_COACHES_ROUTE = '/swimbetter/admins-and-coaches';
const SWIMMERS_ROUTE = '/swimbetter/swimmers';

const ADMINS_AND_COACHES_SWIMBETTER_ROUTES: any = {
  SWIMMERS: {
    MAIN: `${ADMINS_AND_COACHES_ROUTE}/swimmers/`,
    DETAILS: `${ADMINS_AND_COACHES_ROUTE}/swimmers/details/`,
    SEND_INVITES: `${ADMINS_AND_COACHES_ROUTE}/swimmers/send-invites`,
    PENDING_INVITES: `${ADMINS_AND_COACHES_ROUTE}/swimmers/pending-invites`,
    JOIN_REQUESTS: `${ADMINS_AND_COACHES_ROUTE}/swimmers/join-requests`,
  },
  GROUPS: `${ADMINS_AND_COACHES_ROUTE}/groups`,
  ADMINS: {
    MAIN: `${ADMINS_AND_COACHES_ROUTE}/admins`,
    DETAILS: `${ADMINS_AND_COACHES_ROUTE}/admins/details/`,
    SEND_INVITES: `${ADMINS_AND_COACHES_ROUTE}/admins/send-invites`,
    PENDING_INVITES: `${ADMINS_AND_COACHES_ROUTE}/admins/pending-invites`,
    JOIN_REQUESTS: `${ADMINS_AND_COACHES_ROUTE}/admins/join-requests`,
  },
  TEAMS: {
    TEAM_DASHBOARD: `${ADMINS_AND_COACHES_ROUTE}/team-dashboard`,
  },
  ACCOUNTS: {
    CREATE: `${ADMINS_AND_COACHES_ROUTE}/account`,
  },
  DEVICES: `${ADMINS_AND_COACHES_ROUTE}/devices`,
};

const SWIMMERS_SWIMBETTER_ROUTES: any = {
  YOUR_SWIMS: `${SWIMMERS_ROUTE}/your-swims`,
};

export {
  ADMINS_AND_COACHES_ROUTE,
  ADMINS_AND_COACHES_SWIMBETTER_ROUTES,
  SWIMMERS_ROUTE,
  SWIMMERS_SWIMBETTER_ROUTES,
};
