import { useEffect } from 'react';
import { useRouter } from 'next/router';

import Layout from './layout';

import {
  fetchLocalStorageData,
  removeLocalStorageData,
} from '../../utils/localstorage';

import { Profile } from '../../types/profile';

import { useDispatch, useSelector } from '../../hooks/redux-hooks';

import {
  fetchUserTeams,
  resetUserHasSignOut,
  selectUser,
  selectUserHasSignOut,
  signOutUser,
} from '../../redux/user';
import {
  fetchProfile,
  selectProfile,
  selectProfileErrors,
} from '../../redux/profile';
import { selectIsLoading } from '../../redux/selectors';
import { addNotification } from '../../redux/notifications';

import isTokenExpired from '../../utils/json-token-checker';
import { SWIMMERS_SWIMBETTER_ROUTES } from '../../constants/routes';
import { fetchOwnedLicenses } from '../../redux/licenses';
import { fetchTeam } from '../../redux/teams';
import Maintenance from '../maintenance';
import { fetchSharedOwners } from '../../redux/shared-access';

interface Props {
  children: React.ReactNode;
  coach?: boolean;
}

// I didn't add this inside the auth directory as it more of a layout for me and
// its primary function is to check if there is a current user
const AuthLayout = ({ children, coach }: Props) => {
  const dispatch = useDispatch();
  const router = useRouter();

  const user = useSelector(selectUser);
  const userHasSignOut = useSelector(selectUserHasSignOut);
  const profile: Profile = useSelector(selectProfile);
  const profileErrors = useSelector(selectProfileErrors);
  const globalIsLoading: boolean = useSelector(selectIsLoading);

  const localStorageAccessToken = fetchLocalStorageData('accessToken');

  useEffect(() => {
    if (globalIsLoading) {
      return;
    }
    if (process.env.MAINTENANCE !== 'true') {
      if (userHasSignOut) {
        dispatch(resetUserHasSignOut());
        router.push('/sign-in');
        return;
      }

      if (!user?.id) {
        router.push('/sign-in');
        return;
      }

      // If user id is null or access token is expired log out user
      if (
        (localStorageAccessToken && isTokenExpired(localStorageAccessToken)) ||
        (user.id && !localStorageAccessToken)
      ) {
        dispatch(signOutUser());
        router.push('/sign-in');
      } else if (
        profileErrors.length > 0 ||
        (user.firstName === '' && user.lastName === '')
      ) {
        router.push('/account/account-setup');
      }
      // This will be called when user has just signed in
      else if (router.isReady && profile.userId === '' && user.id) {
        fetchUserDetails();
      } else if (router.isReady && profile.userId && user.id) {
        checkIfRedirectRequired();
      }
      // when user just sign in
      //fetch teams and license this data on menu
      if (router.isReady && user.id !== '' && profile.userId) {
        dispatch(fetchOwnedLicenses(user.id));
        dispatch(fetchUserTeams(user.id));
        dispatch(fetchSharedOwners());
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user, profile, profileErrors, router.isReady]);

  const fetchUserDetails = async () => {
    await dispatch(fetchProfile(user.id));

    if (user.products.length === 0) {
      dispatch(
        addNotification({ message: 'No product selection', type: 'error' })
      );
    }
  };

  const checkIfRedirectRequired = () => {
    const inviteId = fetchLocalStorageData('inviteId');
    const action = fetchLocalStorageData('action');
    if (inviteId && action) {
      removeLocalStorageData('inviteId');
      removeLocalStorageData('action');

      router.push({
        pathname: '/licence-manager',
        query: {
          inviteId,
          action,
        },
      });
    } else if (fetchLocalStorageData('teamId')) {
      router.push(`/join-requests/?teamId=${fetchLocalStorageData('teamId')}`);
      // For the meantime we will only consider all users to be swimmers and redirect them to the swimmers page
    } else if (router.pathname === '/') {
      router.push(SWIMMERS_SWIMBETTER_ROUTES.YOUR_SWIMS);
    }
  };

  if (process.env.MAINTENANCE === 'true') {
    return <Maintenance />;
  }
  return <Layout coach={coach}>{user.id ? children : null}</Layout>;
};

export default AuthLayout;
